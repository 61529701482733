<template>
  <div class="Studio" v-if="render">
    <v-container fluid class="py-0">
      <v-row>
        <v-col
          class="pa-0 position-relative content-intro cursor-custom-logo"
          cols="12"
        >
          <template v-if="render">
            <carousel
              :items="1"
              :autoplay="true"
              :loop="true"
              :nav="false"
              :dots="false"
              :animateIn="'fadeIn'"
              :animateOut="'fadeOut'"
              :slideTransition="'linear'"
            >
              <template v-for="(item, i) in studioSingleton.assetContent">
                <div v-bind:key="i">
                  <template v-if="item.value.asset != null">
                    <v-img
                      :src="
                        'https://www.ofisdesigncms.actstudio.xyz' +
                        item.value.asset.path
                      "
                      :style="'animation-delay: ' + (1 + 0.5 * i) + 's;'"
                      :height="heightCarousel"
                      class="animation"
                      position="bottom center"
                    ></v-img>
                  </template>

                  <!-- <template v-else-if="item.value.video == true">
                    <video
                      autoplay
                      loop
                      muted
                      width="100%"
                      style="object-fit: cover; height: 100vh !important"
                    >
                      <source
                        type="video/mp4"
                        :src="
                          'https://www.ofisdesigncms.actstudio.xyz/storage/uploads' +
                          item.value.path
                        "
                      />
                    </video>
                  </template> -->
                </div>
              </template>
            </carousel>
          </template>

          <v-row
            align="center"
            justify="space-around"
            no-gutters
            class="content-intro__white-div animation"
            style="animation-delay: 2s"
          >
            <v-col cols="12" class="px-8 px-sm-5 px-md-3 py-5 py-sm-3">
              <v-row
                no-gutters
                justify="space-between"
                justify-sm="space-around"
              >
                <v-col cols="auto" class="Project--line-height-texts">
                  <span class="d-block titles aeonik-light">Architecture</span>
                  <span class="d-block titles aeonik-light">& Interior</span>
                  <span class="d-block titles aeonik-light">Design</span>
                </v-col>
                <v-col
                  cols="12"
                  sm="auto"
                  class="line-height-18525 mt-5 mt-sm-0"
                  v-if="renderContactInfo"
                >
                  <span class="d-block aeonik-bold">Contacto</span>
                  <a
                    :href="'mailto:' + contactSingleton.card_contact.email"
                    class="not-text-decoration color-black"
                  >
                    <span class="d-block">{{
                      contactSingleton.card_contact.email
                    }}</span>
                  </a>

                  <a
                    target="_blank"
                    class="not-text-decoration color-black"
                    :href="
                      'tel:' + contactSingleton.card_contact.phone_redirect
                    "
                  >
                    <span class="d-block aeonik-light"
                      >T. {{ contactSingleton.card_contact.phone }}</span
                    >
                  </a>

                  <a
                    target="_blank"
                    class="not-text-decoration color-black"
                    :href="contactSingleton.card_contact.address_redirect"
                  >
                    <span class="d-block">{{
                      contactSingleton.card_contact.address
                    }}</span>
                  </a>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- separador mobile -->
          <hr class="d-block d-sm-none hr-mobile" />
          <!-- /separador mobile -->
        </v-col>
      </v-row>
    </v-container>

    <!-- inicia content -->
    <v-container class="px-8 px-sm-12 px-md-3 py-0">
      <!-- row carousels lg to up -->
      <v-row
        class="mt-12 pt-xl-12 Studio__row-studio d-none d-lg-flex arrow-studio position-relative animation"
        justify="space-between"
        style="animation-delay: 2s"
      >
        <v-col cols="auto" class="Project--line-height-texts">
          <v-row
            class="Studio__row-studio--row-column-left"
            style="width: min-content"
            no-gutters
          >
            <v-col cols="12">
              <span class="d-block titles aeonik-light mt-7 mt-xl-12 pt-xl-3"
                >Design</span
              >
              <span class="d-block titles aeonik-light">Construction</span>
              <span class="d-block titles aeonik-light"
                >& Post-construction</span
              >
            </v-col>

            <v-col class="align-self-end">
              <v-carousel
                class="Studio__carousel-column-1"
                hide-delimiter-background
                hide-delimiters
                v-model="carouselPreview"
                v-bind:height="heightCarouselMini"
                v-bind:show-arrows="false"
                v-bind:touchless="true"
              >
                <template v-for="(item, i2) in studioSingleton.assetContent2">
                  <v-carousel-item
                    :key="i2"
                    transition="fade-slow-transition"
                    reverse-transition="fade-slow-transition"
                  >
                    <template v-if="item.value.asset != null">
                      <v-img
                        :src="
                          'https://www.ofisdesigncms.actstudio.xyz' +
                          item.value.asset.path
                        "
                        position="center center"
                        v-bind:height="heightCarouselMini"
                      ></v-img>
                    </template>
                  </v-carousel-item>
                </template>
              </v-carousel>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="auto" class="mx-lg-8">
          <v-carousel
            v-model="carouselNext"
            v-bind:show-arrows="false"
            v-bind:height="heightCarouselCenter"
            hide-delimiters
            hide-delimiter-background
            class="Studio__row-studio--carousel-next"
          >
            <template v-for="(item, i3) in studioSingleton.assetContent2">
              <v-carousel-item
                :key="i3"
                transition="fade-slow-transition"
                reverse-transition="fade-slow-transition"
              >
                <template v-if="item.value.asset != null">
                  <v-img
                    v-bind:src="
                      'https://www.ofisdesigncms.actstudio.xyz' +
                      item.value.asset.path
                    "
                    v-bind:height="heightCarouselCenter"
                    position="center center"
                  ></v-img>
                </template>
              </v-carousel-item>
            </template>
          </v-carousel>
        </v-col>
        <v-col cols="auto">
          <div
            v-html="studioSingleton.description"
            class="Studio__column-description word-spaces"
          ></div>
        </v-col>
        
        <div class="cursor-left" @click="changeCarouselsPreview()"></div>
        <div class="cursor-right" @click="changeCarousels()"></div>
      </v-row>
      <!-- /row carousels lg to up-->

      <!-- row carousels lg to down -->
      <v-row
        class="mt-8 mt-sm-12 Studio__row-studio d-lg-none animation"
        justify="space-between"
        style="animation-delay: 2s"
      >
        <v-col cols="12" class="Project--line-height-texts">
          <v-row no-gutters justify="space-between">
            <v-col cols="12" sm="auto" class="Studio__carousel-column-1">
              <span class="d-block titles aeonik-light">Design</span>
              <span class="d-block titles aeonik-light">Construction</span>
              <span class="d-block titles aeonik-light"
                >& Post-construction</span
              >
            </v-col>
            <v-col
              cols="12"
              sm="auto"
              class="Studio__row-studio--carousel-next mt-5 mt-sm-0"
            >
              <div
                v-html="studioSingleton.description"
                class="Studio__column-description"
              ></div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" class="mx-lg-8 pt-0 pt-sm-3">
          <v-row
            no-gutters
            justify="space-between"
            :style="'height: ' + heightCarouselCenter + 'px;'"
          >
            <v-col cols="auto" align-self="end" class="d-none d-sm-block">
              <v-carousel
                class="Studio__carousel-column-1"
                hide-delimiters
                v-model="carouselPreview"
                v-bind:height="heightCarouselMini"
                v-bind:show-arrows="false"
                v-bind:cycle="cycle"
                @change="carouselNext++"
              >
                <template v-for="(item, i2) in studioSingleton.assetContent2">
                  <v-carousel-item
                    :key="i2"
                    transition="fade-transition"
                    reverse-transition="fade-transition"
                  >
                    <template v-if="item.value.asset != null">
                      <v-img
                        :src="
                          'https://www.ofisdesigncms.actstudio.xyz' +
                          item.value.asset.path
                        "
                        position="center center"
                        v-bind:height="heightCarouselMini"
                      ></v-img>
                    </template>
                  </v-carousel-item>
                </template>
              </v-carousel>
            </v-col>
            <v-col cols="auto">
              <v-carousel
                class="Studio__row-studio--carousel-next"
                hide-delimiters
                v-model="carouselNext"
                v-bind:show-arrows="false"
                v-bind:height="heightCarouselCenter"
                v-bind:cycle="cycle"
              >
                <template v-for="(item, i3) in studioSingleton.assetContent2">
                  <v-carousel-item
                    :key="i3"
                    transition="fade-transition"
                    reverse-transition="fade-transition"
                  >
                    <template v-if="item.value.asset != null">
                      <v-img
                        v-bind:src="
                          'https://www.ofisdesigncms.actstudio.xyz' +
                          item.value.asset.path
                        "
                        v-bind:height="heightCarouselCenter"
                        position="center center"
                      ></v-img>
                    </template>
                  </v-carousel-item>
                </template>
              </v-carousel>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- /row carousels lg to down-->

      <v-row
        class="mt-12 pt-xl-12 cursor-custom-logo animation-lazy"
        align="center"
      >
        <v-col cols="12" sm="6" class="Project--line-height-texts">
          <div class="mb-4">
            <span class="aeonik-bold titles">{{
              studioSingleton.story.titleStory
            }}</span>
          </div>
          <div class="first-paragraph">
            <p class="subtitles">
              {{ studioSingleton.story.firstDescription }}
            </p>
          </div>

          <div class="mt-sm-12">
            <p class="small-texts paragraph-2">
              {{ studioSingleton.story.second }}
            </p>
            <p class="small-texts paragraph-2">
              {{ studioSingleton.story.third }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="6" class="pt-0 pt-sm-3">
          <div>
            <v-img
              :src="
                'https://www.ofisdesigncms.actstudio.xyz' +
                studioSingleton.storyImage.path
              "
              class="Studio--history-img"
            ></v-img>
          </div>
        </v-col>
      </v-row>

      <!-- clientes -->
      <v-row class="mt-8 mt-sm-12 pt-xl-12 animation-lazy">
        <v-col cols="12" class="pb-3">
          <span class="aeonik-bold titles">Nuestros Clientes</span>
        </v-col>
      </v-row>

      <v-row class="mb-12 pb-xl-12 animation-lazy">
        <template v-for="(client, cl) in clients">
          <v-col cols="6" sm="4" md="3" xl="2" v-bind:key="cl" @mouseover="idClient = client._id" @mouseleave="idClient = null">
            <template v-if="client.project != ''">
              <v-card
                class="card-custom activate-overlay"
                tile
                elevation="0"
                :to="'/project/' + client.project.display"
              >
                <v-row
                  class="card-custom--row"
                  align="center"
                  justify="center"
                  no-gutters
                >
                  <v-col cols="auto" class="px-5 text-center">
                    <template v-if="client.icon == null">
                      <span class="card-custom--row--span aeonik-bold titles">
                        {{ client.name }}
                      </span>
                    </template>

                    <template v-else>
                      <v-img
                        :src="
                          'https://www.ofisdesigncms.actstudio.xyz' +
                          client.icon.path
                        "
                        :class="{ 'remove-filter': idClient == client._id}"
                        class="filter-gray"
                      ></v-img>
                    </template>
                  </v-col>
                </v-row>
                <!-- overlay -->
              </v-card>

              <!-- <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card
                    class="card-custom activate-overlay"
                    tile
                    elevation="0"
                    :to="'/project/' + client.project.display"
                  >
                    <v-row
                      class="card-custom--row"
                      align="center"
                      justify="center"
                      no-gutters
                    >
                      <v-col cols="auto" class="px-5 text-center">
                        <template v-if="client.icon == null">
                          <span
                            class="card-custom--row--span aeonik-bold titles"
                          >
                            {{ client.name }}
                          </span>
                        </template>

                        <template v-else>
                          <v-img
                            :src="
                              'https://www.ofisdesigncms.actstudio.xyz/' +
                              client.icon.path
                            "
                          ></v-img>
                        </template>
                      </v-col>
                    </v-row>

                    <v-overlay
                      absolute
                      opacity="1"
                      class="text-center"
                      v-if="hover"
                      v-bind:color="client.overlayColor"
                      z-index="1"
                    >
                      <span
                        class="card-custom--row--span aeonik-bold titles"
                        :class="{
                          'white--text':
                            client.overlayColor == '#223151' ||
                            client.overlayColor == '#1F6D5E',
                          'black--text':
                            client.overlayColor != '#223151' ||
                            client.overlayColor != '#1F6D5E',
                        }"
                      >
                        {{ client.name }}
                      </span>
                    </v-overlay>
                  </v-card>
                </template>
              </v-hover> -->
            </template>

            <template v-else>
              <v-card class="card-custom activate-overlay" tile elevation="0">
                <v-row
                  class="card-custom--row"
                  align="center"
                  justify="center"
                  no-gutters
                >
                  <v-col cols="auto" class="px-5 text-center">
                    <template v-if="client.icon == null">
                      <span class="card-custom--row--span aeonik-bold titles">
                        {{ client.name }}
                      </span>
                    </template>

                    <template v-else>
                      <v-img
                        :src="
                          'https://www.ofisdesigncms.actstudio.xyz' +
                          client.icon.path
                        "
                        :class="{ 'remove-filter': idClient == client._id}"
                        class="filter-gray"
                      ></v-img>
                    </template>
                  </v-col>
                </v-row>
                <!-- overlay -->
              </v-card>

              <!-- por si acaso con el overlay -->
              <!-- <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card
                    class="card-custom activate-overlay"
                    tile
                    elevation="0"
                  >
                    <v-row
                      class="card-custom--row"
                      align="center"
                      justify="center"
                      no-gutters
                    >
                      <v-col cols="auto" class="px-5 text-center">
                        <template v-if="client.icon == null">
                          <span class="card-custom--row--span aeonik-bold titles">
                            {{ client.name }}
                          </span>
                        </template>

                        <template v-else>
                          <v-img :src="'https://www.ofisdesigncms.actstudio.xyz/'+client.icon.path"></v-img>
                        </template>
                      </v-col>
                    </v-row>

                    <v-overlay
                      absolute
                      opacity="1"
                      class="text-center pa-6"
                      v-if="hover"
                      v-bind:color="client.overlayColor"
                      z-index="1"
                    >
                      <span
                        class="card-custom--row--span aeonik-bold titles"
                        :class="{
                          'white--text':
                            client.overlayColor == '#223151' ||
                            client.overlayColor == '#1F6D5E',
                          'black--text':
                            client.overlayColor != '#223151' ||
                            client.overlayColor != '#1F6D5E',
                        }"
                      >
                        {{ client.name }}
                      </span>
                    </v-overlay>
                  </v-card>
                </template>
              </v-hover> -->
            </template>
          </v-col>
        </template>
      </v-row>
      <!-- /clientes -->
    </v-container>
    <!-- /termina content -->
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import "animate.css";

export default {
  components: {
    carousel,
  },
  data() {
    return {
      render: false,
      renderContactInfo: false,
      studioSingleton: {},
      contactSingleton: {},
      carouselIntro: 0,
      carouselPreview: 1,
      carouselNext: 0,
      cycle: false,
      heightCarousel: 0,
      heightCarouselMini: 230,
      heightCarouselCenter: 0,
      clients: [],
      color: "",
      idClient: null,
    };
  },
  mounted() {
    //resize about height carousel
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });

    this.$emit("update", 1);

    //fetch para el singleton de contacto
    fetch(
      "https://www.ofisdesigncms.actstudio.xyz/api/singletons/get/contact?token=e96ec97538f5b51ad80b44386e6d1c"
    )
      .then((data) => data.json())
      .then((data) => (this.contactSingleton = data))
      .then((data) => (this.renderContactInfo = true))
      .catch((error) => console.log(error.message));

    //fetch for studio´s singleton
    fetch(
      "https://www.ofisdesigncms.actstudio.xyz/api/singletons/get/Studio?token=e96ec97538f5b51ad80b44386e6d1c"
    )
      .then((data) => data.json())
      .then((data) => (this.studioSingleton = data))
      .then((data) => (this.render = true))
      .catch((error) => console.log(error.message));

    //fetch for clients
    fetch(
      "https://www.ofisdesigncms.actstudio.xyz/api/collections/get/Clients?token=e96ec97538f5b51ad80b44386e6d1c",
      {
        method: "post",
        headers: { "Content-Type": "applications/json" },
        body: JSON.stringify({}),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.clients = res.entries))
      .catch((error) => console.log(error.message));
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.onResize, { passive: true });
    }
  },
  methods: {
    onResize() {
      //height carousel intro
      if (window.innerWidth <= 599) {
        this.heightCarousel = 450;
      } else if (window.innerWidth >= 600 && window.innerWidth <= 959) {
        this.heightCarousel = 350;
      } else if (window.innerWidth >= 960 && window.innerWidth <= 1263) {
        this.heightCarousel = 450;
      } else if (window.innerWidth >= 1264 && window.innerWidth <= 1903) {
        this.heightCarousel = 550;
      } else if (window.innerWidth >= 1904) {
        this.heightCarousel = 700;
      }

      //height carousel mini
      if (window.innerWidth <= 599) {
        this.heightCarouselMini = 300;
      } else if (window.innerWidth >= 600 && window.innerWidth <= 1903) {
        this.heightCarouselMini = 230;
      } else if (window.innerWidth >= 1904) {
        this.heightCarouselMini = 352;
      }

      //height carousel center
      if (window.innerWidth <= 599) {
        this.heightCarouselCenter = 300;
      } else if (window.innerWidth >= 600 && window.innerWidth <= 959) {
        this.heightCarouselCenter = 384;
      } else if (window.innerWidth >= 960 && window.innerWidth <= 1903) {
        this.heightCarouselCenter = 523;
      } else if (window.innerWidth >= 1904) {
        this.heightCarouselCenter = 700;
      }

      if (window.innerWidth <= 959) {
        this.cycle = true;
      } else {
        this.cycle = false;
      }
    },
    changeCarousels: function () {
      this.carouselPreview++;

      setTimeout(() => {
        this.carouselNext++;
      }, 500);
    },
    changeCarouselsPreview: function () {
      this.carouselPreview--;

      if (this.carouselPreview < 0) {
        this.carouselPreview = this.studioSingleton.assetContent2.length - 1
      }

      setTimeout(() => {
        this.carouselNext--;

        if (this.carouselNext < 0) {
          this.carouselNext = this.studioSingleton.assetContent2.length - 1
        }

      }, 500);
    }
  },
};
</script>